<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In a terrible accident, a solution consisting of
        <number-value :value="massHNO3" unit="\text{kg}" />
        of nitric acid,
        <stemble-latex content="$\ce{HNO3,}$" />
        was spilled on the lab floor. Very quickly, you decide to throw
        <stemble-latex content="$2.00\text{ kg}$" />
        of sodium carbonate,
        <stemble-latex content="$\ce{Na2CO3}$" />, on the spilled acid. You then run out of the
        building due to the significant quantities of carbon dioxide that would form due to the
        reaction. As you stand outside, you wonder, "Did I add enough sodium carbonate to neutralize
        the acid?".
      </p>

      <p class="mb-2">a) Did you add enough sodium carbonate to neutralize the acid?</p>

      <v-radio-group v-model="inputs.enoughAdded" class="pl-7" :disabled="!allowEditing">
        <div v-for="option in options" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-2">
        b) In the event that you did not add enough sodium carbonate, how much extra would be needed
        to completely neutralize the acid? If you have added enough, please enter a value of 0
        (zero).
      </p>

      <calculation-input
        v-model="inputs.amountNeeded"
        class="mb-5"
        prepend-text="$\text{Needed}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) In the event that you did add enough sodium carbonate, how much extra did you add? If you
        didn't add any extra, please enter a value of 0 (zero).
      </p>

      <calculation-input
        v-model="inputs.excessAdded"
        class="mb-5"
        prepend-text="$\text{Excess}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question235',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        enoughAdded: null,
        amountNeeded: null,
        excessAdded: null,
      },
      options: [
        {condition: '$\\text{Yes}$', value: 'yes'},
        {condition: '$\\text{No}$', value: 'no'},
      ],
    };
  },
  computed: {
    massHNO3() {
      return this.taskState.getValueBySymbol('massHNO3').content;
    },
  },
};
</script>
